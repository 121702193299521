<script>
import { Carousel, Slide } from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import planApi from "@/apis/planApi";
import storage from '../storage';
import Features from "@/components/features";
// import Plans from "@/components/plans1";
import Plans from "@/components/plans";
import Testimonial from "@/components/testimonial";
import Navbar2 from "../components/navbar2.vue";


/**
 * Index-1
 */
export default {
  data() {
    return {
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        name:"",
        pageNum: 1,
        pageSize: 12
      }
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Plans,
    Features,
    Testimonial,
    Navbar2
},
  created() {
    //获取项目列表
    planApi.list(this.planForm, res => {
      this.plans = res.data
      //获取到的数据小于每页数据时
      if (this.plans.result.length < this.planForm.pageSize) this.plansLoad = -1
    })
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  methods: {
    loadmore(page) {
      //开始加载
      this.plansLoad = 1
      this.planForm.pageNum = page
      //获取项目列表
      planApi.list(this.planForm, res => {
        this.plansLoad = 0
        Object.assign(this.plans, res.data)
      })
    },
    //用于手机加载
    loadmore2() {
      this.planForm.pageNum++
      //开始加载
      this.plansLoad = 1
      let pages = Math.ceil(this.plans.totalCount / this.planForm.pageSize)
      //获取项目列表
      if (pages >= this.planForm.pageNum) {
        planApi.list(this.planForm, res => {
          res.data.result.unshift(...this.plans.result)
          Object.assign(this.plans, res.data)
          this.plansLoad = 0
          if (pages <= this.planForm.pageNum) this.plansLoad = -1//结束加载
        })
      } else {
        this.plansLoad = -1;
      }
    }
  }

};
</script>

<template>
  <div>
    <Navbar2 />
    <!-- 顶部 -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Choose Your Plans</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Choose Your Plans
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!-- 遮蔽罩 -->
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->
    <section class="section">
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="14" :xs="24">
            <h4>Choose Your Plans</h4>
          </el-col>
          <el-col :span="6" :xs="16">
            <el-input v-model="planForm.name" placeholder="Search Name..."></el-input>
          </el-col>
          <el-col :span="4" :xs="4">
            <b-button @click="loadmore(1)">Search</b-button>
          </el-col>
        </el-row>
        <el-row v-if="plans==undefined" v-loading="plans==undefined">
        </el-row>
        <el-row v-else v-loading="plansLoad == 1">
          <Plans :data="plans.result"></Plans>
        </el-row>
        <!-- <div class="mt-3 window">
          <div style="display: flex;justify-content: center;">
            <b-pagination v-if="plans!=undefined" :disabled="plansLoad == 1" size="lg" v-model="planForm.pageNum"
              @change="loadmore" :total-rows="plans.totalCount" :per-page="planForm.pageSize">
            </b-pagination>
          </div>
        </div>
        <div class="mt-3 phone">
          <div v-if="plans!=undefined" class="d-grid gap-2">
            <b-button v-if="plansLoad == 0" block class="btn btn-primary" @click="loadmore2">Load More</b-button>
            <b-button v-else-if="plansLoad == 1" block class="btn btn-primary disabled">Loading...</b-button>
            <b-button v-else block class="btn btn-primary disabled">No More</b-button>
          </div>
        </div> -->
      </div>
    </section>


    <Footer />
    <!-- Footer End -->
<!--    <Switcher />-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
