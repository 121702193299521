<script>
import date from "../date"
import storage from "../storage";
import PlanDetail from "./planDetail.vue";


export default {
  data() {
    return {
      PlanData: {},
      Show: false
    }
  },
  props: {
    data: Array
  },
  filters: {
    getDate(data) {
      return date.getDate(data);
    }
  },
  computed: {
    dataList() {
      return this.data.filter(p => p.status == 1)
    }
  },
  methods: {
    desc(data) {
      //判断是否为登录状态来觉得十分进入登录页面
      if (storage.localGet("userId")) {
        this.Show = true
        this.PlanData = data
      } else {
        this.Show = true
        this.PlanData = data
        // this.$message.warning("You need to login before you can do this")
        // this.$router.push("/auth-login")
      }
    },
    ShowModal() {
      this.Show = !this.Show
    },
  },
  components: {PlanDetail}
};
</script>

<template>
  <div >
    <el-row :gutter="20" type="flex" class="plan-row" justify="center">
      <el-col :span="6" :xs="24" class="mt-4" v-for="(item) of dataList" :key="item.id">
        <div  class="card pricing-rates business-rate shadow bg-light border-0 rounded plan-card">
          <div class="ribbon ribbon-right ribbon-warning overflow-hidden" v-if="item.progress === 100">
            <span class="text-center d-block shadow small h6">Sold Out</span>
          </div>
          <!--          style="background-color: #0a4499; border-radius: 0.25rem;color:white;"-->
          <div class="card-body"  style="text-align: center;background-color: #0a4499;color:white; border-radius: 0.25rem;">
            <!--            <h6 class="title font-weight-bold text-uppercase text-primary mb-4">-->
            <h6 class="title font-weight-bold text-uppercase mb-4">
              {{ item.name }}
            </h6>
            <div>
              <!--              <div class="d-flex mb-4">-->
              <!--                <span class="h1 mb-0">{{ (item.percent * 100).toFixed(2) }}</span>-->
              <!--                <span class="h4 mb-0 mt-2">%</span>-->
              <!--              </div>-->
              <div class="mb-4"  >
                <span class="price h4 mb-0"><strong>${{ item.amount }} / {{ item.period | getDate }}</strong></span>
              </div>
              <!--           text-muted   -->
              <div>Contract Term: <span class="h5 mb-0" style="color: #ffffff;">{{ item.period | getDate }}</span></div><br/>
              <div>Contract Price: <span class="h5 mb-0" style="color: #ffffff;">${{ item.amount }}</span></div><br/>
              <!--              <div class="d-flex mb-4">-->
              <!--                <span>Include Maintenance Fee and Electricity Fee</span>-->
              <!--              </div>-->
              <div>
                <span>Payment method: <span style="color: #ffffff;font-weight:Bold;">Cryptocurrency</span></span>
              </div><br/>
              <ul class="list-unstyled mb-0 pl-0">
                <!--                <li class="h4 text-muted mb-0">-->
                <!--                  <div>{{ item.period | getDate }}</div>-->
                <!--                </li>-->
                <li style="font-size: 16px;">
                  Revenue 24h:
                  <span style="color:#ffffff;"><strong>${{ (item.amount * item.percent).toFixed(2) }}</strong></span>
                </li><br/>
                <div v-if="item.limitTime>1">
                  <li style="font-size: 16px;" v-if="item.showType===1">
                    Monthly revenue:
                    <span style="color:#ffffff;"><strong>${{ (item.amount * item.percent * 30).toFixed(2) }}</strong></span>
                  </li><br v-if="item.showType===1"/>
                </div>
                <li style="font-size: 16px;" v-if="item.showType===2">
                  {{item.period}} Days Fixed Return:<br/>
                  <span style="color:#ffffff;"><strong>${{ item.amount  }} + ${{ (item.amount * item.percent * item.period).toFixed(2) }}</strong></span>
                </li>
              </ul>
              <div v-if="item.showType===3">
                <span>Bonus for newly registered members: <span style="color: #ffffff;font-weight:Bold;">$50</span></span>
              </div><br v-if="item.showType===3"/>
              <b-button class="btn btn-light mt-4" v-b-modal="'plan-buy'"  :disabled="item.progress==100"
                        @click="desc(item)">Buy Now
              </b-button>
              <el-row class="mt-2">
                <label style="font-size: 12px;">Sold out at 100%</label>
                <b-progress :max="100" animated>
                  <b-progress-bar :value="item.progress">
                    <span><strong>{{ item.progress }}%</strong></span>
                  </b-progress-bar>
                </b-progress>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
      <!--end col-->
    </el-row>
    <PlanDetail :item="PlanData" :show="Show" @modal="ShowModal"></PlanDetail>
  </div>
</template>

<style scoped lang="scss">
.plan-row{
  flex-wrap: wrap;
}

.plan-card{
  height: 100%;
  .card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
</style>
