import { render, staticRenderFns } from "./buyNow.vue?vue&type=template&id=8d6e4dca&scoped=true"
import script from "./buyNow.vue?vue&type=script&lang=js"
export * from "./buyNow.vue?vue&type=script&lang=js"
import style0 from "./buyNow.vue?vue&type=style&index=0&id=8d6e4dca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d6e4dca",
  null
  
)

export default component.exports