<script>
import planApi from '../apis/planApi';
import date from '../date';
import {Message} from 'element-ui';

export default {
  props: {
    plan: Object,
    showBuyNow: Boolean
  },
  data() {
    return {
      isUkraine: false,
      password: "",
      quantity: 1,
      alert: {
        text: "",
        show: false
      },
      paying: false,
      isShowBuyNow: this.showBuyNow,
    }
  },
  watch: {
    showBuyNow(val) {
      // console.log('showBuyNow:' + val)
      this.isShowBuyNow = val
    },
    isShowBuyNow(val) {
      // console.log('isShowBuyNow:' + val)
      if (!val) {
        this.$emit("buyNowModal")
      }
    }
  },
  filters: {
    getDate(data) {
      return date.getDate(data);
    }
  },
  created: function () {
    // console.log('aaa:' + this.plan.id)
    // if (this.showPay) {
    //   this.isShow = true
    // }
  },
  mounted() {
    this.checkIsUkraineAndNightTime();
  },
  methods: {
    showModal() {
      // this.$emit("buyNowModal")
      this.isShowBuyNow = false
      this.quantity = 1
      // this.alert.text = ''
      this.alert.show = false
      this.password = ''
    },
    checkIsUkraineAndNightTime(){
      const isUk = navigator.language.toLowerCase() === 'uk-ua' || navigator.language.toLowerCase() === 'uk' || navigator.language.toLowerCase() === 'ru-ru';
      const currentUTCHour = new Date().getUTCHours();
      const isNightTime = currentUTCHour >= 7 && currentUTCHour < 18;
      console.log(navigator.language.toLowerCase());
      console.log('isUkraine:' + isUk + '   isNightTime:' + isNightTime + currentUTCHour);
      this.isUkraine = isUk && isNightTime;
    },
    pay() {
      //密码不得为空
      if (this.password === "") {
        this.alert.text = "Password cannot be empty"
        this.alert.show = true
        return
      }
      //支付请求开始
      this.paying = true
      planApi.buy({
        planId: this.plan.id,
        password: btoa(this.password),
        quantity: this.quantity
      }, res => {
        //请求返回内容
        this.paying = false
        if (res.code != 200) {
          this.alert.text = res.msg
          this.alert.show = true
        } else {
          //支付成功关闭窗口
          Message.success({
            message: "success"
          })
          this.alert.show = false
          // this.isShow = false
          // this.$emit("modal")
          this.isShowBuyNow = false
        }
      })
    },
    transform(html) {
      if (!html) return '';
      return html.replace(/\\n/g, "<br/>")
    }
  },
  computed: {
     getAmount: function (){
       if (this.quantity !== 1) {
         console.log('amount:' + this.plan.amount)
         console.log('quantity:' + this.quantity)
         return this.plan.amount * this.quantity
       } else {
         return this.plan.amount * 1
       }
     }
  }
}
</script>

<template>
  <b-modal title="Checkout" centered v-model="isShowBuyNow" @hide="showModal">
    <div v-loading="paying">
      <el-alert v-if="isUkraine" type="warning" show-icon :closable="false"
                title="Канал депозитів UAH вже доступний онлайн.">
        <a href="/profile/deposits">Дізнатися більше</a>
      </el-alert>

      <el-row class="mb-3">
        <label class="font-weight-bold mb-0" style="font-size:14px">Quantity</label>
        <div>
          <el-input-number v-model="quantity" style="width: 100%;" controls-position="left" :min="1"
                           :max="plan.maxQtyPerOrder"></el-input-number>
        </div>
      </el-row>
      <el-row class="mb-3">
        <label class="font-weight-bold mb-0" style="font-size:14px">Amount</label>
        <el-input placeholder="Please input" v-model="getAmount" disabled>
          <template slot="append">
<!--            $-->
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
                  fill="#00A478"/>
              <path d="M15.9894 17.4429V13.3308H10.4715V9.72632H25.8894V13.3812H20.3715V17.4429H15.9894Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.1 17.7921C8.1 16.4808 12.5118 15.4125 18 15.4125C23.4882 15.4125 27.9 16.4808 27.9 17.7921C27.9 19.1034 23.4882 20.1717 18 20.1717C12.5118 20.1717 8.1 19.1034 8.1 17.7921ZM27.0792 17.7921C26.7201 17.3061 23.7447 15.8013 18 15.8013C12.2553 15.8013 9.2799 17.2575 8.9208 17.7921C9.2799 18.2772 12.2553 19.0062 18 19.0062C23.796 19.0062 26.7201 18.2772 27.0792 17.7921Z"
                    fill="white"/>
              <path
                  d="M20.3715 18.7127V15.8696C19.6497 15.8183 18.8766 15.7679 18.1035 15.7679C17.3817 15.7679 16.6608 15.7679 15.9903 15.8183V18.6614C16.6248 18.7613 17.3898 18.7802 18.1035 18.7802C18.8163 18.7802 19.6497 18.7631 20.3715 18.7127ZM18 20.3777C17.2782 20.3777 16.659 20.3579 15.9894 20.3075V27.9017H20.3202V20.2859C19.5984 20.3372 18.7731 20.3777 18 20.3777Z"
                  fill="white"/>
            </svg>
          </template>
        </el-input>
      </el-row>
      <el-row class="mb-3">
        <label class="font-weight-bold mb-0" style="font-size:14px">Password</label>
        <el-input v-model="password" title="Password" type="password" show-password>
        </el-input>
      </el-row>

      <el-alert class="mt-2" v-if="alert.show" :title="alert.text" :closable="false" show-icon
                type="error"></el-alert>
<!--      <el-alert class="mt-2" v-else title="Default payment password: 666777" :closable="false" show-icon-->
<!--                type="info"></el-alert>-->

    </div>
    <template #modal-footer>
      <div class="w-100">
        <!--        <b-button variant="danger" class="float-left" @click="showPay = false; password = '';alert.show = false">-->
        <!--          Back-->
        <!--        </b-button>-->
        <b-button variant="primary" class="float-left ml-2" @click="pay">
<!--          {{plan.id}}-->
          Buy Now
        </b-button>
      </div>
    </template>
  </b-modal>
</template>


<style scoped lang="scss">
.el-usdt {

  background: url('../assets/icon/usdt.svg') center center no-repeat;
}


</style>

