<script>
import date from '../date';
import storage from "@/storage";
import BuyNow from "@/components/buyNow";


export default {
  data() {
    return {
      // PlanIds:[
      //   40,41,42
      // ]
      showBuyNow: false,
      plan: this.item,
      isShowDetail: this.show,
      bitCoinNum: this.bitCoin
    }
  },
  props: {
    item: Object,
    show: Boolean,
    bitCoin:String
  },
  watch: {
    show(val){
      console.log('show:'+val)
      this.isShowDetail = val
    },
    isShowDetail(val){
      console.log('isShowDetail:'+val)
      if(!val){
        this.$emit("modal",val)
      }
    },
    bitCoin(val){
      this.bitCoinNum = val
    }
  },
  filters: {
    getDate(data) {
      return date.getDate(data);
    }
  },
  created: function () {
    console.log('isShowDetail:'+this.isShowDetail)
    // let userId = storage.localGet("userId")
    // if (userId) {
    //   // this.showBuyNow = true
    // }
  },
  methods: {
    buyNowModal() {
      console.log('child showBuyNow:' + this.showBuyNow)
      this.showBuyNow = !this.showBuyNow
      console.log('showBuyNow change:' + this.showBuyNow)
    },
    showModal(tab,event) {
      // console.log(tab,event)
      this.isShowDetail = false
      // this.$emit("modal",this.isShowDetail)
      // this.$emit("modal")
    },
    transform(html) {
      if (!html) return '';
      return html.replace(/\\n/g, "<br/>")
    },
    buy() {
      //if login
      let userId = storage.localGet("userId")
      if (!userId) {
        this.$router.push("/auth-login")
      } else {
        this.showBuyNow = true
        this.plan = this.item
      }
    }
  },
  components: {BuyNow}
}
</script>

<template>
  <div>
    <b-modal title="Details" v-model="isShowDetail" size="xl" @close="showModal" scrollable>
      <!--      <el-row style="height: 100%">-->
      <el-row :gutter="20">
                <el-col :span="24" :md="12">
                  <div style="position: relative;">
<!--                  <img src="../assets/1.BCH.png" style="width: 100%;height: 100%;border-radius: 5px">-->
                    <img :src="item.presignedUrl" style="width: 100%;height: 100%;border-radius: 5px">
                    <div v-if="item.progress === 100" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5);">
                      <img src="@/assets/img/sold-out1.png" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width:240px;">
                    </div>
                  </div>
                </el-col>
        <el-col style="height: 100%" :span="24" :md="12">
          <!--          <img src="../assets/1.BCH.png" style="width: 100%;height: 100%;border-radius: 5px">-->
<!--          <img src="../assets/1.BCH.png" style="border-radius: 5px;width: 60%;height: 60%;">-->
          <el-row :gutter="20" style="height: 100%;overflow-y: auto">
            <el-col :span="24" :xs="24">
<!--              <div class="ribbon ribbon-right ribbon-warning overflow-hidden" v-if="item.progress === 100">-->
<!--                <span class="text-center d-block shadow small h6">Sold Out</span>-->
<!--              </div>-->
              <div class="card-body">
                <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
                  {{ item.name }}
                </h6>
                <div class="d-flex mb-4">
                  <span class="price h4 mb-0"><strong>${{ item.amount }} / {{ item.period | getDate }}</strong></span>
                </div>
                <div>Contract Term: <span class="h5 mb-0" style="color: #2f55d4;">{{ item.period | getDate }}</span>
                </div>
                <br/>
                <div>Contract Price: <span class="h5 mb-0" style="color: #2f55d4;">${{ item.amount }}</span></div>
                <br/>
                <div>
                  <span>Payment method: <span class="h5 mb-0" style="color: #2f55d4;">Cryptocurrency </span> </span>
                </div>
                <br/>
                <!--                  <el-row class="mb-2">-->
                <!--                    Limit Qty:&nbsp;<strong>{{ item.limitTime }}</strong>-->
                <!--                  </el-row>-->

                <ul class="list-unstyled mb-0 pl-0">
                  <!--                <li class="h4 text-muted mb-0">-->
                  <!--                  <div>{{ item.period | getDate }}</div>-->
                  <!--                </li>-->
                  <li style="font-size: 16px;">
                    Daily Rewards:
                    <span style="color:#2f55d4;"><strong>{{ ((item.amount * item.percent)/bitCoinNum).toFixed(8) }}  BTC <br/>( ≈ ${{ (item.amount * item.percent).toFixed(2) }} )</strong></span>
                  </li>
                  <br/>
                  <li v-if="item.limitTime!==1 && item.showType===1" style="font-size: 16px;">
                    Monthly revenue:
                    <span style="color:#2f55d4;"><strong>${{
                        (item.amount * item.percent * 30).toFixed(2)
                      }}</strong></span>
                    <!--                      Fixed Return:-->
                    <!--                      <span style="color:#2f55d4;"><strong>${{ item.amount  }} + ${{ (item.amount * item.percent * item.period).toFixed(2) }}</strong></span>-->
                  </li>
                  <br v-if="item.limitTime!==1 && item.showType===1"/>
                  <li style="font-size: 16px;" v-if="item.showType===2">
                    {{ item.period === 1 ? '1 Day' : item.period + ' Days' }} Fixed Rewards:
                    <span style="color:#2f55d4;">
                      <strong>${{item.amount }} + ${{ (item.amount * item.percent * item.period).toFixed(2) }}</strong>
                    </span>
                  </li>
                  <br v-if="item.showType===2"/>
                </ul>
                <div v-if="item.showType===3">
                  <span>{{ item.period === 1 ? '1 Day' : item.period + ' Days' }} Fixed Rewards: ${{ (item.amount * item.percent).toFixed(2) }}</span>
                </div>
                <br v-if="item.showType===3"/>
                <el-row class="mt-1">
                  <div class="font-12">Sold out at 100%</div>
                  <b-progress :max="100" animated>
<!--                    <b-progress-bar :value="item.progress" :variant="item.progress === 100 ? 'danger' : ''">-->
                    <b-progress-bar :value="item.progress" variant="danger">
                      <span><strong>{{ item.progress }}%</strong></span>
                    </b-progress-bar>
                  </b-progress>
                </el-row>
              </div>
            </el-col>
            <el-col :span="24" :xs="24">
              <div class="card-body">
                <h6 class="title font-weight-bold text-uppercase text-primary mb-4">Desc</h6>
                <div v-html="transform(item.longDesc)" style="white-space:pre-wrap;"></div>
              </div>
              <BuyNow :plan="plan" :showBuyNow="showBuyNow" @buyNowModal="buyNowModal"></BuyNow>

            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="danger" class="float-left ml-2" @click="showModal">
            Close
          </b-button>
          <!--          <router-link to="/auth-login">-->
          <b-button variant="primary" class="float-left ml-2" @click="buy" :disabled="item.progress === 100">
            Order Now
          </b-button>
          <!--          </router-link>-->
        </div>
      </template>

    </b-modal>
  </div>
</template>

<style scoped lang="scss">


</style>
